import React, { useState } from "react";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");
	const [isSubscribed, setIsSubscribed] = useState(false);

	const handleEmailChange = (e) => {
		setEmail(e.target.value);
		setError(""); // Clear error when user starts typing again
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		// Basic email validation
		const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
		if (!email.match(emailPattern)) {
			setError("Please enter a valid email address.");
			return;
		}

		// Simulate a subscription action
		setIsSubscribed(true);
		setEmail("");
	};

	return (
		<div>
			<footer
				id="footer"
				className="bg-gray-900 text-white py-8 overflow-x-hidden">
				<div className="container flex flex-col items-center sm:justify-center tablet:items-start tablet:flex-row tablet:justify-between tablet:gap-12 space-y-8 tablet:space-y-0">
					{/* Useful Links */}
					<div className="footer-links xl:ml-6 text-center tablet:text-left xl:-my-2 tablet:-my-4 tablet:ml-4">
						<h4 className="text-lg sm:mt-4 tablet:text-xl xl:text-2xl font-semibold mb-4">
							Useful Links
						</h4>
						<ul className="space-y-2 tablet:space-y-3">
							<li>
								<Link
									to="/"
									className="text-sm tablet:text-base xl:text-lg text-white hover:text-gray-400 transition-all duration-300">
									Home
								</Link>
							</li>
							<li>
								<Link
									to="/about"
									className="text-sm tablet:text-base xl:text-lg text-white hover:text-gray-400 transition-all duration-300">
									About Us
								</Link>
							</li>
							<li>
								<Link
									to="/contact"
									className="text-sm tablet:text-base xl:text-lg text-white hover:text-gray-400 transition-all duration-300">
									Contact
								</Link>
							</li>
							<li>
								<Link
									to="/privacy-policy"
									className="text-sm tablet:text-base xl:text-lg text-white hover:text-gray-400 transition-all duration-300">
									Privacy Policy
								</Link>
							</li>
							<li>
								<Link
									to="/terms"
									className="text-sm tablet:text-base xl:text-lg text-white hover:text-gray-400 transition-all duration-300">
									Terms and Conditions
								</Link>
							</li>
						</ul>
					</div>

					{/* Social Links */}
					<div className="footer-info text-center tablet:text-left">
						<h3 className="text-lg tablet:text-xl xl:text-3xl font-bold mb-2">
							SoftGrid Info Pvt Ltd
						</h3>
						<div className="flex flex-col items-center tablet:items-start space-y-4">
							<a
								href="https://www.instagram.com/softgrid_info_pvt_ltd/"
								className="flex items-center text-sm tablet:text-base xl:text-lg text-white hover:text-gray-400 transition-all duration-300">
								<FaInstagram className="mr-2 h-6" />
								Instagram
							</a>
							<a
								href="https://www.linkedin.com/company/softgrid-info-pvt-ltd/mycompany/"
								className="flex items-center text-sm tablet:text-base xl:text-lg text-white hover:text-gray-400 transition-all duration-300">
								<FaLinkedin className="mr-2 h-6" />
								LinkedIn
							</a>
						</div>
					</div>

					{/* Newsletter */}
					<div className="footer-newsletter hidden tablet:block text-center tablet:text-left xl:mr-8 tablet:mr-4">
						<h4 className="text-sm tablet:text-base xl:text-2xl font-semibold mb-4">
							Subscribe to Our Newsletter
						</h4>
						<form
							onSubmit={handleSubmit}
							className="flex flex-col items-center tablet:flex-row tablet:space-x-4">
							<input
								type="email"
								name="email"
								placeholder="Your email"
								value={email}
								onChange={handleEmailChange}
								className="px-4 py-2 text-black text-sm rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400 w-full tablet:w-auto mb-2 tablet:mb-0"
							/>
							<button
								type="submit"
								className={`px-4 py-2 text-sm tablet:text-base text-white rounded-md ${
									isSubscribed
										? "bg-gray-500 cursor-not-allowed"
										: "bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 hover:scale-110 transition-all duration-300"
								}`}
								disabled={isSubscribed}>
								{isSubscribed ? "Subscribed" : "Subscribe"}
							</button>
						</form>
						{error && <p className="text-red-500 text-sm mt-2">{error}</p>}
						{isSubscribed && (
							<p className="text-green-500 text-sm mt-2">
								Thank you for subscribing!
							</p>
						)}
						<p className="text-sm tablet:text-base xl:text-lg mt-4">
							Stay updated with our newsletter.
						</p>
					</div>
				</div>
			</footer>
			<p className="text-center bg-slate-900 text-white text-xs tablet:text-sm py-2">
				&copy; 2024 SoftGrid Info Pvt Ltd. All Rights Reserved.
			</p>
		</div>
	);
};

export default Footer;

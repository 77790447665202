import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Careers from "./pages/Careers";
import LandingPage from "./pages/Landingpage";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";

function App() {
	return (
		<Router>
			<div className="App">
				<Header />

				<Routes>
					<Route path="/" element={<LandingPage />} />
					<Route path="/career" element={<Careers />} />
					<Route path="/about" element={<About />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/privacy-policy" element={<Privacy />} />
					<Route path="/terms" element={<Terms />} />
				</Routes>
				<Footer />
			</div>
		</Router>
	);
}

export default App;

import React from "react";

const About = () => {
	return (
		<div className="bg-stone-50 w-full">
			<div className="relative container mx-auto ">
				{/* Company Overview Section */}
				<section className="relative text-center h-96 bg-slate-900 mb-12 overflow-hidden py-16">
					<div className="absolute inset-0 z-10 opacity-50">
						<div className="absolute bottom-10 right-10 w-32 sm:w-40 md:w-48 lg:w-56 xl:w-52 h-32 sm:h-40 md:h-48 lg:h-56 xl:h-64 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 blur-3xl opacity-70 mix-blend-multiply animate-spin"></div>
						<div className="absolute bottom-1/2 left-1/3 w-28 sm:w-36 md:w-44 lg:w-52 xl:w-56 h-28 sm:h-36 md:h-44 lg:h-52 xl:h-56 bg-gradient-to-r from-red-500 via-purple-500 to-pink-500 blur-3xl opacity-90 mix-blend-multiply"></div>
					</div>

					<div className="absolute inset-0 bg-black opacity-40"></div>
					<div className="relative z-10 lg:mt-12 tablet:mt-24 sm:mt-9 ">
						<h1 className="text-3xl sm:text-2xl  lg:text-3xl tablet:text-4xl font-extrabold text-white mb-4">
							About SoftGrid Info Pvt Ltd
						</h1>
						<p className="w-full sm:w-11/12 lg:w-11/12 tablet:w-11/12  xl:11/12 text-sm sm:text-base lg:text-lg xl:text-lg mx-auto text-white opacity-90">
							At SoftGrid Info Pvt Ltd, we are passionate about creating
							innovative solutions that drive success and deliver long-lasting
							value. Our expert team is dedicated to providing cutting-edge
							technology services to businesses worldwide.
						</p>
					</div>
				</section>

				{/* Mission & Vision Section */}
				<div className="flex flex-col lg:flex-row justify-between items-start lg:items-stretch mb-12 gap-8 lg:gap-16 px-4">
					{/* Mission */}
					<section className="text-center w-full lg:w-1/2">
						<h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
							Our Mission
						</h2>
						<p className="text-sm sm:text-base lg:text-lg text-gray-600 text-justify max-w-3xl mx-auto">
							Our mission is to empower businesses with innovative software
							solutions that streamline processes, enhance productivity, and
							fuel growth. We aim to be a trusted partner in helping businesses
							adapt and thrive in an ever-evolving digital landscape.
						</p>
					</section>

					{/* Vision */}
					<section className="text-center w-full lg:w-1/2">
						<h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-800 mb-4">
							Our Vision
						</h2>
						<p className="text-sm sm:text-base lg:text-lg text-gray-600 text-justify max-w-3xl mx-auto">
							Our vision is to be a global leader in technology solutions,
							delivering value-driven results to clients across industries. We
							envision a world where technology bridges the gap between
							businesses and their goals, bringing them closer to success.
						</p>
					</section>
				</div>

				{/* Core Values Section */}
				<section className="text-center mb-12 px-4 lg:px-8">
					<h2 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-gray-800 mb-8">
						Our Core Values
					</h2>
					<div className="grid xl:grid-cols-3 sm:grid-cols-1 lg:grid-cols-1 gap-6 mb-10">
						{/* Core Value 1 */}
						<div className="bg-stone-50 p-6 sm:p-8 rounded-xl shadow-xl transition-transform transform hover:scale-105 duration-300  ">
							<h3 className="text-lg sm:text-xl lg:text-2xl font-semibold text-gray-800 mb-3">
								Integrity
							</h3>
							<p className="text-gray-600 text-justify text-sm sm:text-base">
								We uphold the highest standards of integrity in all our actions,
								ensuring transparency and honesty in every business interaction.
							</p>
						</div>

						{/* Core Value 2 */}
						<div className="bg-stone-50 p-6 sm:p-8 rounded-xl shadow-xl transition-transform transform hover:scale-105 duration-300">
							<h3 className="text-lg sm:text-xl lg:text-2xl font-semibold text-gray-800 mb-3">
								Innovation
							</h3>
							<p className="text-gray-600 text-justify text-sm sm:text-base">
								We are committed to fostering creativity and innovation,
								constantly pushing boundaries to bring the most advanced
								solutions to our clients.
							</p>
						</div>

						{/* Core Value 3 */}
						<div className="bg-stone-50 p-6 sm:p-8 rounded-xl shadow-xl transition-transform transform hover:scale-105 duration-300">
							<h3 className="text-lg sm:text-xl lg:text-2xl font-semibold text-gray-800 mb-3">
								Excellence
							</h3>
							<p className="text-gray-600 text-justify text-sm sm:text-base">
								Excellence is at the heart of everything we do. We strive to
								deliver superior results in every project we undertake, ensuring
								satisfaction and success.
							</p>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
};

export default About;

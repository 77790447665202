import React, { useState } from "react";

import JobDetails from "../components/JobDetails";

const Careers = () => {
	return (
		<div className="  ">
			<div className="relative bg-gray-900 mb-12">
				<div className="absolute inset-0 z-10 opacity-50">
					<div className="absolute bottom-10 right-10 w-32 h-32 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 blur-3xl opacity-70 mix-blend-multiply animate-spin overflow-hidden"></div>
					<div className="absolute bottom-1/2 left-1/3 w-28 sm:w-36 md:w-44 lg:w-52 xl:w-56 h-28 sm:h-36 md:h-44 lg:h-52 xl:h-56 bg-gradient-to-r from-red-500 via-purple-500 to-pink-500 blur-3xl opacity-90 mix-blend-multiply"></div>
				</div>
				<h2 className="xl:text-6xl sm:text-4xl font-bold text-center text-white pt-32 mb-2">
					Careers
				</h2>
				<div className="flex flex-col justify-center items-center py-12">
					<h1 className="text-3xl sm:text-lg tablet:text-xl xl:text-2xl font-semibold text-white mb-4">
						Join Our Team at SoftGrid Info Pvt Ltd
					</h1>
					<p className="text-lg sm:text-sm sm:w-11/12 tablet:text-lg xl:text-xl   text-white text-center mb-24 lgr:w-1/2">
						We are constantly looking for talented individuals to join our team.
						Explore our career opportunities below and become part of a dynamic,
						innovative, and supportive environment where your ideas are valued
						and your skills can shine.
					</p>
				</div>
			</div>
			<JobDetails />
		</div>
	);
};

export default Careers;

import { useState } from "react";
import { Link } from "react-router-dom";

function Header() {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<header className="fixed top-0 w-full h-20 bg-slate-50 bg-opacity-80 shadow-md z-50">
			<div className="relative container flex items-center justify-between py-10 px-6">
				{/* Logo aligned to the extreme left */}
				<h1 className="absolute left-0 flex items-center space-x-2 ml-4">
					<Link
						to="/"
						className="pl-4 flex items-center text-gray-900 font-bold tablet:text-xl sm:text-base xl:text-2xl">
						<img
							src="/sg.png"
							alt="Logo"
							className="h-12 sm:h-7 tablet:h-12 xl:h-16 sm:-ml-8 mr-2 rounded-full"
						/>
						SoftGrid Info Pvt Ltd<span className="text-primary">.</span>
					</Link>
				</h1>

				{/* Desktop Navigation (extreme right for larger screens) */}
				<nav className="absolute right-0 hidden xl:flex space-x-8 mr-4">
					<Link to="/" className="text-gray-700 hover:text-primary">
						Home
					</Link>
					<Link to="/about" className="text-gray-700 hover:text-primary">
						About Us
					</Link>
					<Link to="/career" className="text-gray-700 hover:text-primary">
						Career
					</Link>
					<a
						href="https://play.google.com/store/apps/details?id=com.softgrid.student_register"
						target="_blank"
						rel="noopener noreferrer"
						className="text-gray-700 hover:text-primary">
						Download
					</a>
					<Link to="/contact" className="text-gray-700 hover:text-primary">
						Contact
					</Link>
				</nav>

				{/* Mobile Navigation Button */}
				<button
					className="absolute right-0 tablet:block tablet:mr-4 sm:mr-4 md:mr-4 xl:hidden text-2xl text-gray-700"
					onClick={() => setIsOpen(!isOpen)}>
					☰
				</button>
			</div>

			{/* Mobile Navigation */}
			{isOpen && (
				<div className="bg-white shadow-lg">
					<nav className="space-y-4 p-6">
						<Link to="/" className="block text-gray-700 hover:text-primary">
							Home
						</Link>
						<Link
							to="/about"
							className="block text-gray-700 hover:text-primary">
							About Us
						</Link>
						<Link
							to="/career"
							className="block text-gray-700 hover:text-primary">
							Career
						</Link>
						<a
							href="https://play.google.com/store/apps/details?id=com.softgrid.student_register"
							target="_blank"
							rel="noopener noreferrer"
							className="block text-gray-700 hover:text-primary">
							Download
						</a>
						<Link
							to="/contact"
							className="block text-gray-700 hover:text-primary">
							Contact
						</Link>
					</nav>
				</div>
			)}
		</header>
	);
}

export default Header;

import { useState } from "react";

const JobDetails = ({}) => {
	const [openIndex, setOpenIndex] = useState(null);

	const toggleAccordion = (index) => {
		setOpenIndex(openIndex === index ? null : index);
	};
	const jobData = [
		{
			title: ".Net Developer",
			company: "SoftGrid Info Pvt Ltd",
			location: "Pune, Maharashtra",
			salary: "₹:2.5-3.5 L.P.A ",
			openings: "10-15",
			description: [
				"Bachelor's degree in Computer Science or related field",
				"Strong experience in Object-Oriented Programming (OOP) and Software Development",
				"Experience in leading development teams is a plus",
			],
			responsibilities: [
				"2 to 4 years of experience as a .Net Developer, with a strong focus on .Net Core, MVC, and Angular.",
				"Proficiency in C# and the .Net framework.",
				"Extensive experience with Angular and front-end web development.",
				"Sound understanding of database management and SQL.",
				"Exceptional problem-solving skills and a keen eye for detail.",
				"Strong communication and teamwork skills.",
			],
			skills: [
				".NET Core",
				".NET MVC",
				"C#",
				"Database Management",
				"Front-end Developer",
				"Angular",
				"SQL",
				"Back-end Developer",
				"Microsoft Visual Studio",
			],
		},
		{
			title: "Flutter Developer",
			company: "SoftGrid Info Pvt Ltd",
			location: "Pune, Maharashtra",
			salary: "₹:2.5-3.5 L.P.A ",
			openings: "10-20",
			description: [
				"Bachelor's degree in Computer Science or related field",
				"Strong experience in Object-Oriented Programming (OOP) and Software Development",
				"Experience in leading development teams is a plus",
			],
			responsibilities: [
				"Design and Build sophisticated and highly scalable apps using Flutter.",
				"Write efficient queries for core Data.",
				"Resolve any problems existing in the system and suggest and add new features.",
				"Use CI/CD for smooth deployment.",
				"Document the project and code efficiently.",
				"Experience with version control systems (e.g., Git)",
				"Strong proficiency in Flutter, Dart, and mobile app development & full mobile development life cycle",
			],
			skills: [
				"Mobile Development",
				"Flutter Development",
				"MVP",
				"Firebase",
				"Design",
				"Dart",
			],
		},
		{
			title: "Python Developer",
			company: "SoftGrid Info Pvt Ltd",
			location: "Pune, Maharashtra",
			salary: "₹:5-6 L.P.A ",
			openings: "10-20",
			description: [
				"Bachelor's and Master's degree in Computer Science or related field",
				"Strong experience in Object-Oriented Programming (OOP) and Software Development",
				"Experience in leading development teams is a plus",
			],
			responsibilities: [
				"Python development engineer",
				"Job description: iOS and Android mobile app development",
				"Development of software, including requirements analysis, collaboration with the design team",
				"Writing scalable, efficient, and secure code.",
				"Design and implement RESTful APIs.",
				"Monitor and optimize performance of applications.",
			],
			skills: [
				"AWS/Azure/GCP",
				"Test-Driven Development (TDD)",
				"NoSQL",
				"MongoDB",
				"DynamoDB",
				"Flask",
				"Python",
				"Python Microservices",
				"Python Fullstack",
			],
		},
		{
			title: "MERN Developer",
			company: "SoftGrid Info Pvt Ltd",
			location: "Pune, Maharashtra",
			salary: " ₹:3-5 L.P.A ",
			openings: "15-20",
			description: [
				"Bachelor's degree in Computer Science or related field",
				"Strong experience in JavaScript and web development",
				"Experience with MongoDB, Express, React, and Node.js (MERN stack)",
				"Experience in leading development teams is a plus",
			],
			responsibilities: [
				"3 to 5 years of experience as a MERN Developer with a strong focus on React and Node.js.",
				"Proficiency in MongoDB, Express.js, React.js, and Node.js (MERN stack).",
				"Experience with front-end and back-end development in JavaScript.",
				"Solid understanding of REST APIs, server-side technologies, and databases.",
				"Strong problem-solving skills and ability to troubleshoot complex issues.",
				"Excellent communication skills and ability to work in a team.",
			],
			skills: [
				"MongoDB",
				"Express.js",
				"React.js",
				"Node.js",
				"JavaScript",
				"RESTful APIs",
				"HTML/CSS",
				"Git",
				"Database Management",
				"Agile Methodologies",
			],
		},
		{
			title: "Java Developer",
			company: "SoftGrid Info Pvt Ltd",
			location: "Pune, Maharashtra",
			salary: "₹:4.5-5 L.P.A ",
			openings: "10-30",
			description: [
				"Bachelor's degree in Computer Science or related field",
				"Strong experience in Java and object-oriented programming (OOP)",
				"Experience with Spring, Hibernate, and Java EE",
				"Experience in leading development teams is a plus",
			],
			responsibilities: [
				"3 to 5 years of experience as a Java Developer with expertise in Java and Spring Framework.",
				"Proficiency in Java, J2EE, and related frameworks (Spring, Hibernate).",
				"Experience with database design and management (SQL and NoSQL).",
				"Familiarity with cloud computing and deployment platforms.",
				"Strong problem-solving skills and ability to optimize code performance.",
				"Collaborate with cross-functional teams to define, design, and ship new features.",
			],
			skills: [
				"Java",
				"Spring Framework",
				"Hibernate",
				"J2EE",
				"SQL",
				"NoSQL Databases",
				"Microservices",
				"Restful Web Services",
				"Apache Maven",
				"Version Control (Git)",
			],
		},
	];

	return (
		<div className="container mx-auto p-4 max-w-full overflow-hidden">
			{jobData.map((job, index) => (
				<div
					key={index}
					className="bg-stone-50 shadow-lg rounded-lg xl:m-8 sm:m-4 md:m-4 lg:m-4 tablet:m-6 mb-2 transition-all hover:shadow-xl">
					<details open={openIndex === index}>
						<summary
							className="flex justify-between items-center sm:p-4 xl:p-6 bg-gradient-to-r from-gray-800 via-slate-700 to-gray-600 cursor-pointer text-white rounded-t-lg"
							onClick={() => toggleAccordion(index)}>
							<h2 className="xl:text-2xl sm:text-base md:text-lg lg:text-xl xl:text-xl tablet:text-xl font-semibold">
								{job.title}
							</h2>
							<svg
								className={`transform transition-transform duration-300 ${
									openIndex === index ? "rotate-180" : ""
								}`}
								width="24"
								height="24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									d="M6 9l6 6 6-6"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</summary>

						<div className="p-2 space-y-4 bg-stone-50">
							{/* Job Details */}
							<div className="flex flex-wrap justify-between gap-6">
								<div className="flex-1 text-gray-800">
									<p className="font-medium text-gray-700">
										<strong>Company:</strong> {job.company}
									</p>
									<p className="font-medium text-gray-700">
										<strong>Location:</strong> {job.location}
									</p>
									<p className="font-medium text-gray-700">
										<strong>Salary:</strong> {job.salary}
									</p>
									<p className="font-medium text-gray-700">
										<strong>Openings:</strong> {job.openings}
									</p>
								</div>
								<div className="flex-1 text-gray-800">
									<p className="font-medium text-gray-700">
										<strong>Job Type:</strong> Full-time
									</p>
									<p className="font-medium text-gray-700">
										<strong>Role:</strong> Other
									</p>
									<p className="font-medium text-gray-700">
										<strong>Department:</strong> Other
									</p>
									<p className="font-medium text-gray-700">
										<strong>Education:</strong> Any Graduate
									</p>
								</div>
							</div>

							{/* Job Description and Responsibilities */}
							<div className="flex flex-col tablet:flex-row xl:flex-row gap-4">
								<div className="w-full">
									<h3 className="font-semibold text-lg text-gray-800">
										Job Description
									</h3>
									<ul className="space-y-2 text-sm font-semibold text-gray-700">
										{job.description.map((item, index) => (
											<li key={index}>{item}</li>
										))}
									</ul>
								</div>
								<div className="w-full">
									<h3 className="font-semibold text-lg text-gray-800">
										Responsibilities
									</h3>
									<ul className="space-y-2 text-sm font-semibold text-gray-700">
										{job.responsibilities.map((item, index) => (
											<li key={index}>{item}</li>
										))}
									</ul>
								</div>
							</div>

							{/* Skills Required */}
							<div>
								<h3 className="font-semibold text-lg text-gray-800">
									Skills Required
								</h3>
								<ul className="pl-6 space-x-1 sm:text-sm font-semibold text-gray-700 flex flex-wrap gap-1">
									{job.skills.map((skill, index) => (
										<li key={index} className="list-disc list-inside">
											{skill}
											{index < job.skills.length - 1 && ","}
										</li>
									))}
								</ul>
							</div>
						</div>

						{/* Apply Button */}
						<div className="flex justify-center">
							<button
								onClick={() =>
									(window.location.href =
										"mailto:hr@softgridinfo.in?subject=Job Application&body=Please find my application attached.")
								}
								className="border border-red-500 mt-2 px-6 py-2 rounded-full text-base font-semibold hover:shadow-lg mb-8 text-black transition duration-300">
								Apply Now
							</button>
						</div>
					</details>
				</div>
			))}
		</div>
	);
};

export default JobDetails;

import React from "react";

const Terms = () => {
	return (
		<div className=" bg-gray-100 ">
			<div className="mt-24 mx-auto bg-white p-6 shadow-lg rounded-lg">
				<h1 className="text-3xl font-bold text-gray-800 mb-4">
					Terms and Conditions
				</h1>
				<p className="text-gray-600 mb-6">
					Welcome to our Terms and Conditions page. By accessing or using our
					website, you agree to be bound by these terms. If you do not agree to
					these terms, please do not use our website.
				</p>
				<h2 className="text-2xl font-semibold text-gray-800 mb-3">
					Use of the Website
				</h2>
				<p className="text-gray-600 mb-4">
					You agree to use the website only for lawful purposes and in a way
					that does not infringe the rights of others or restrict or inhibit
					their use and enjoyment of the website.
				</p>
				<h2 className="text-2xl font-semibold text-gray-800 mb-3">
					Intellectual Property
				</h2>
				<p className="text-gray-600 mb-4">
					All content on this website, including text, graphics, logos, and
					images, is the property of our company and is protected by copyright
					and trademark laws.
				</p>
				<h2 className="text-2xl font-semibold text-gray-800 mb-3">
					Limitation of Liability
				</h2>
				<p className="text-gray-600 mb-4">
					We are not liable for any damages or losses arising from your use of
					this website. This includes direct, indirect, incidental, or
					consequential damages.
				</p>
				<h2 className="text-2xl font-semibold text-gray-800 mb-3">
					Changes to Terms
				</h2>
				<p className="text-gray-600 mb-4">
					We reserve the right to modify these terms at any time. Any changes
					will be posted on this page, and it is your responsibility to review
					them periodically.
				</p>
				<p className="text-gray-600">
					If you have any questions or concerns about these terms, please
					contact us at
					<a
						href="mailto:hr@softgridinfo.in"
						className="text-blue-500 underline ml-1">
						hr@softgridinfo.in
					</a>
					.
				</p>
			</div>
		</div>
	);
};

export default Terms;

import React from "react";

const Privacy = () => {
	return (
		<div className="w-full mt-12  bg-gray-100 ">
			<div className="w-full mx-auto bg-white p-6 shadow-lg rounded-lg">
				<h1 className="text-3xl font-bold text-gray-800 mb-4">
					Privacy Policy
				</h1>
				<p className="text-gray-600 mb-6">
					Welcome to our Privacy Policy page. We are committed to protecting
					your personal information and your right to privacy. If you have any
					questions or concerns about our policy or our practices regarding your
					personal information, please contact us.
				</p>
				<h2 className="text-2xl font-semibold text-gray-800 mb-3">
					Information We Collect
				</h2>
				<p className="text-gray-600 mb-4">
					We collect personal information that you provide to us such as name,
					address, contact information, passwords, and security data. We also
					collect information automatically when you interact with our website.
				</p>
				<h2 className="text-2xl font-semibold text-gray-800 mb-3">
					How We Use Your Information
				</h2>
				<p className="text-gray-600 mb-4">
					We use your personal information to provide, operate, and maintain our
					services, improve and personalize user experience, and comply with
					legal obligations.
				</p>
				<h2 className="text-2xl font-semibold text-gray-800 mb-3">
					Sharing Your Information
				</h2>
				<p className="text-gray-600 mb-4">
					We only share information with your consent, to comply with laws, to
					provide services, or to protect your rights. We do not sell your
					personal information to third parties.
				</p>
				<h2 className="text-2xl font-semibold text-gray-800 mb-3">
					Your Privacy Rights
				</h2>
				<p className="text-gray-600 mb-4">
					Depending on your location, you may have rights under applicable data
					protection laws, such as the right to access, update, or delete your
					personal data.
				</p>
				<p className="text-gray-600">
					If you have any questions or comments about this policy, feel free to
					contact us at
					<a
						href="mailto:hr@softgridinfo.in"
						className="text-blue-500 underline ml-1">
						hr@softgridinfo.in
					</a>
					.
				</p>
			</div>
		</div>
	);
};

export default Privacy;

import React, { useState, useEffect, useRef } from "react";

const ClientLogos = () => {
	const [logos, setLogos] = useState([
		"/client-2.png",
		"/client-4.png",
		"/client-5.png",
		"/gold.png",
		"/mt.jpg",
	]);

	const scrollContainerRef = useRef(null);

	// Auto scroll logic
	useEffect(() => {
		const updateScrollSpeed = () => {
			const scrollSpeed = window.innerWidth < 768 ? 20 : 50; // Smaller speed for small devices, faster for large devices
			return scrollSpeed;
		};

		let scrollSpeed = updateScrollSpeed();

		const scrollInterval = setInterval(() => {
			if (scrollContainerRef.current) {
				scrollContainerRef.current.scrollBy({
					left: 2, // Smooth scroll step
					behavior: "smooth",
				});

				// Check if scroll reached the end and reset to start
				if (
					scrollContainerRef.current.scrollLeft +
						scrollContainerRef.current.offsetWidth >=
					scrollContainerRef.current.scrollWidth
				) {
					scrollContainerRef.current.scrollTo({ left: 0, behavior: "smooth" });
				}
			}
		}, scrollSpeed);

		// Update speed on resize
		const handleResize = () => {
			clearInterval(scrollInterval);
			scrollSpeed = updateScrollSpeed();

			setInterval(() => {
				if (scrollContainerRef.current) {
					scrollContainerRef.current.scrollBy({
						left: 2,
						behavior: "smooth",
					});
				}
			}, scrollSpeed);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			clearInterval(scrollInterval);
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	// Duplicate logos to create the looping effect
	const loopedLogos = [...logos, ...logos];

	return (
		<section className="relative text-center py-8 bg-white">
			<h2 className="text-xl md:text-3xl xl:text-5xl font-bold mb-6">
				Our Clients
			</h2>

			<div
				ref={scrollContainerRef}
				className="flex justify-center items-center space-x-4 overflow-x-scroll scrollbar-hide md:space-x-8 lg:space-x-16 xl:space-x-32 scroll-smooth"
				style={{
					whiteSpace: "nowrap",
				}}>
				{loopedLogos.map((logo, index) => (
					<img
						key={index}
						src={logo}
						alt={`Client ${index + 1}`}
						className="h-16 sm:h-20 md:h-24 lg:h-28 xl:h-32 object-contain inline-block"
					/>
				))}
			</div>
		</section>
	);
};

export default ClientLogos;

import React from "react";
import { useState, useEffect } from "react";

const Contact = () => {
	const images = ["/123.jpg", "/234.jpg"];

	// State to manage the current image index
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	// Function to go to the next image
	const nextImage = () => {
		setCurrentImageIndex((prevIndex) =>
			prevIndex === images.length - 1 ? 0 : prevIndex + 1
		);
	};
	const prevImage = () => {
		setCurrentImageIndex((prevIndex) =>
			prevIndex === 0 ? images.length - 1 : prevIndex - 1
		);
	};
	useEffect(() => {
		const interval = setInterval(nextImage, 5000); // 3000 ms = 3 seconds
		return () => clearInterval(interval); // Cleanup the interval when the component is unmounted
	}, []);
	return (
		<section
			id="contact"
			className="contact py-12 overflow-x-hidden bg-gray-50">
			<div className="relative w-full h-[500px] mt-12">
				{/* Carousel Image */}
				<img
					src={images[currentImageIndex]}
					alt="carousel"
					className="object-cover w-full h-full"
				/>

				{/* Left Arrow */}
				<button
					onClick={prevImage}
					className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-50 opacity-75 text-black px-4 py-3 rounded-full shadow-md sm:left-2 md:left-4 lg:left-6 xl:left-8">
					&#8592;
				</button>

				{/* Right Arrow */}
				<button
					onClick={nextImage}
					className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-50 opacity-75 text-black px-4 py-3 rounded-full shadow-md sm:right-2 md:right-4 lg:right-6 xl:right-8">
					&#8594;
				</button>
			</div>
			<div className="container mt-24 mx-auto " data-aos="fade-up">
				<div className="section-title text-left ml-6 mb-8">
					<h2 className="text-4xl font-bold text-gray-800 sm:text-center xl:text-start">
						Contact
					</h2>
				</div>

				<div className="flex sm:flex-col md:flex-col lg:flex-col tablet:flex-col xl:flex-row justify-start mt-10">
					{/* Location and Email Section */}
					<div className=" w-full  mb-10 ">
						<div className="p-6">
							<div className="address mb-4">
								<i className="bi bi-geo-alt text-xl text-blue-500"></i>

								<div className="">
									<p className="flex flex-col text-gray-900 xl:w-full tablet:w-1/2 mt-2 mb-4">
										<span className="font-bold text-xl">United Kingdom:</span>{" "}
										Walton House 20 Coleford Road, London SW18 1AD{" "}
										<span className="font-semibold">
											Email : info@softgridinfo.in{" "}
										</span>
										<span className="font-semibold">
											LandLine Number: +44 (0) 203 818 8161
										</span>
									</p>

									<p className="flex flex-col text-gray-900 xl:w-full tablet:w-1/2">
										<span className="font-bold text-xl">India:</span> Regus Pune
										Office Centers Services Pvt. Ltd. Level 6, Pentagon 2,
										Magarpatta City, Pune – 411028
										<span className="font-semibold">
											Email : hr@softgridinfo.in{" "}
										</span>
										<span className="font-semibold">
											LandLine Number: 020-26123323
										</span>
									</p>
								</div>
							</div>
						</div>
					</div>

					{/* Contact Form */}
					<div className="w-64  tablet:ml-16 lg:ml-2 lg:w-10/12 md:ml-4  sm:h-auto md:w-72 xl:mr-40 tablet:w-[600px] xl:w-[1600px] -mt-12 ">
						<form
							onSubmit={(e) => {
								e.preventDefault(); // Prevent default form submission behavior
								const form = e.target;
								const name = form.name.value;
								const email = form.email.value;
								const subject = form.subject.value;
								const message = form.message.value;

								// Construct the mailto link
								const mailtoLink = `mailto:hr@softgridinfo.in?subject=${encodeURIComponent(
									subject
								)}&body=${encodeURIComponent(
									`Name: ${name}\nEmail: ${email}\n\nMessage:\n${message}`
								)}`;

								// Open the mailto link
								window.location.href = mailtoLink;
							}}
							className="w-5/6 sm:ml-8 md:mr-4 lg:mr-8 tablet:mr-8 xl:mr-8 sm:w-full  bg-white p-8 shadow-lg rounded-lg">
							<div className="row mb-6">
								<div className="col-md-6 form-group">
									<input
										type="text"
										name="name"
										className="form-control w-full p-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
										id="name"
										placeholder="Your Name"
										required
									/>
								</div>
								<div className="col-md-6 form-group mt-3 mt-md-0">
									<input
										type="email"
										className="form-control w-full p-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
										name="email"
										id="email"
										placeholder="Your Email"
										required
									/>
								</div>
							</div>
							<div className="form-group mb-6">
								<input
									type="text"
									className="form-control w-full p-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
									name="subject"
									id="subject"
									placeholder="Subject"
									required
								/>
							</div>
							<div className="form-group mb-6">
								<textarea
									className="form-control w-full p-4 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
									name="message"
									rows="5"
									placeholder="Your Message"
									required></textarea>
							</div>

							<div className="text-center">
								<button
									type="submit"
									className="px-8 py-3 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 text-white rounded-lg">
									Send Message
								</button>
							</div>
						</form>
					</div>
				</div>

				{/* Branches Section */}
				<div className="ml-6 mt-16 ">
					<h3 className="text-4xl font-bold text-gray-800 sm:text-center mb-6">
						Our Branches
					</h3>
					<div className="flex justify-center gap-4  tablet:items-center   flex flex-wrap ">
						{/* Branch Card */}
						<div className=" sm:w-full sm:mr-4 md:w-full  xl:w-[400px] xl:pb-8 bg-white p-4 shadow-lg rounded-lg">
							<img
								src="/london.jpg"
								alt="Branch 1"
								className="w-full h-80 sm:h-52 object-cover rounded-md mb-4"
							/>
							<h4 className="text-xl font-semibold text-gray-800">
								Softgrid Pvt Info Ltd
							</h4>
							<p className="text-gray-600 text-sm tablet:pb-6 ">
								Walton St, United Kingdom
							</p>
						</div>
						<div className=" sm:w-full sm:mr-4 md:w-full  xl:w-[400px] bg-white p-4 shadow-lg rounded-lg">
							<img
								src="/pune.jpg"
								alt="Branch 1"
								className="w-full h-80 sm:h-52 object-cover rounded-md mb-4"
							/>
							<h4 className="text-xl font-semibold text-gray-800">
								Softgrid Pvt Info Ltd
							</h4>
							<p className="text-gray-600 xl:text-sm tablet:pb-6">
								Regus Pune Office Centers Services Pvt. Ltd. Level 6, Pentagon
								2, Magarpatta City, Pune – 411028
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Contact;

import React from "react";
import ClientLogos from "../components/ClientLogos";
import gsap from "gsap";
import { useState, useEffect, useRef } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);
const LandingPage = () => {
	const [activeIndex, setActiveIndex] = useState(null);

	const toggleAccordion = (index) => {
		setActiveIndex(activeIndex === index ? null : index);
	};

	const happyClientsRef = useRef(null);
	const projectsRef = useRef(null);
	const experienceRef = useRef(null);
	const supportRef = useRef(null);
	const sectionRef = useRef();
	useEffect(() => {
		// Animation triggered by ScrollTrigger
		const animateNumbers = (element, targetValue) => {
			gsap.fromTo(
				element,
				{ innerText: 0 },
				{
					innerText: targetValue,
					duration: 3,
					snap: { innerText: 1 },
					ease: "power2.out",
					scrollTrigger: {
						trigger: sectionRef.current,
						start: "top 50%", // Trigger animation when section reaches the middle of the viewport

						toggleActions: "play reverse play reverse", // Play forward on scroll in, reverse on scroll out
					},
				}
			);
		};

		// Trigger animations for each number
		animateNumbers(happyClientsRef.current, 150);
		animateNumbers(projectsRef.current, 200);
		animateNumbers(experienceRef.current, 2);
		animateNumbers(supportRef.current, 70);
	}, []);
	return (
		<div className="overflow-x-hidden">
			{/* Hero Section with Custom Gradient Background */}
			<section className="relative xl:w-full h-[500px] text-center py-20 lg:py-32 text-white bg-slate-950 mb-4 ">
				{/* Gradient Blobs */}
				<div className="absolute inset-0 z-10 opacity-50">
					<div className="absolute bottom-10 right-10 w-32 sm:w-40 md:w-48 lg:w-56 xl:w-64 h-32 sm:h-40 md:h-48 lg:h-56 xl:h-64 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 blur-3xl opacity-70 mix-blend-multiply animate-spin"></div>
					<div className="absolute bottom-1/2 left-1/3 w-28 sm:w-36 md:w-44 lg:w-52 xl:w-56 h-28 sm:h-36 md:h-44 lg:h-52 xl:h-56 bg-gradient-to-r from-red-500 via-purple-500 to-pink-500 blur-3xl opacity-90 mix-blend-multiply"></div>
				</div>

				{/* Text Content */}
				<div className="relative flex flex-col items-center justify-center space-y-6  sm:px-8 lg:px-12  mx-auto">
					<h1 className="xl:text-7xl xl:w-full sm:mt-20 lg:mt-8 xl:mt-16 sm:text-4xl tablet:mt-20 font-bold mb-4 leading-tight">
						Everything Starts With Ambition{" "}
						<span className="text-yellow-300">.</span>
					</h1>
					<h2 className="text-sm sm:text-sm sm:w-1/2 md:text-lg md:w-full lg:text-xl xl:text-2xl mx-auto">
						We turn bold ideas into groundbreaking business solutions for a
						tomorrow built on technology.
					</h2>
				</div>
			</section>

			{/* About Us Section */}
			<section className="  bg-gradient-to-r from-stone-50 via-white to-gray-100 relative sm:text-center">
				<h2 className="xl:text-5xl sm:text-2xl xl:text-center tablet:text-3xl font-extrabold xl:mr-0 xl:ml-0 text-gray-800 mb-16">
					About Us
				</h2>
				<div className="flex sm:flex-col md:flex-col lg:flex-col tablet:flex-col xl:flex-row">
					{/* Left Content */}
					<div className="text-left sm:ml-auto md:ml-auto  tablet:ml-8 sm:text-center">
						<p className="xl:text-lg tablet:text-xl sm:text-sm text-gray-600 leading-relaxed mb-6 text-justify sm:ml-8 sm:w-5/6 xl:w-3/4">
							At Softgrid Info Pvt Ltd, we combine technology and innovation to
							create sustainable solutions for a brighter future. By leveraging
							cutting-edge technologies, we empower organizations to transform
							and unlock their full potential. Our team of skilled professionals
							is dedicated to delivering tailored solutions that align with our
							clients' unique goals.
						</p>

						<p className="xl:text-lg tablet:text-xl sm:text-sm text-gray-600 leading-relaxed mb-6 text-justify sm:ml-8 sm:w-5/6 xl:w-3/4">
							We prioritize user-centric designs and robust functionalities to
							ensure exceptional experiences across all platforms. Through a
							collaborative approach, we help businesses streamline processes
							and drive impactful growth. Innovation is at the heart of
							everything we do, enabling us to stay ahead in a fast-evolving
							tech landscape. We believe in fostering strong partnerships built
							on trust, transparency, and mutual success.
						</p>

						<p className="xl:text-lg tablet:text-xl sm:text-sm text-gray-600 leading-relaxed mb-6 text-justify sm:ml-8 sm:w-5/6 xl:w-3/4">
							Sustainability is not just a goal; it is an integral part of our
							mission to create a positive global impact. With a commitment to
							excellence, we continuously push boundaries to deliver
							groundbreaking results. Together, we shape the future by turning
							ambitious visions into transformative realities. Our vision for
							the future is rooted in a commitment to both progress and
							responsibility, ensuring that every step we take is impactful and
							meaningful.
						</p>
					</div>
					<div className="absolute z-10 bottom-24 left-0 w-24 h-24 bg-gradient-to-r from-purple-500 to-pink-500 blur-3xl transform translate-x-1/2 translate-y-1/2"></div>
					<div className="absolute z-10 bottom-32 left-36 w-24 h-24 bg-gradient-to-r from-red-500 to-orange-500 blur-3xl transform translate-x-1/2 translate-y-1/2"></div>

					{/* Right Content: Video and Button */}
					<div className="relative flex justify-center items-center sm:mx-auto lg:mr-36">
						{/* Decorative Gradient Blob */}

						{/* Video */}
						<div className="tablet:-mr-24">
							<div className="relative sm:items-center shadow-lg rounded-md overflow-hidden">
								<video
									className="w-full hidden xl:block h-64 lg:h-80 object-cover"
									autoPlay
									loop
									muted>
									<source src="/company.mp4" type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							</div>
							{/* Button */}
							<Link to="/about">
								<button className="bg-orange-500 lg:ml-32 tablet:text-xl mt-4 xl:text-xl sm:ml-4 mt-2 tablet:mr-64 text-white px-6 py-2 sm:px-12 sm:text-sm sm:mb-4 rounded-full text-base xl:mr-40 xl:py-2 font-semibold shadow-lg hover:bg-orange-600 transition duration-300 whitespace-nowrap">
									Learn More
								</button>
							</Link>
						</div>
					</div>
				</div>
			</section>

			{/* Client Features Section */}
			<ClientLogos />

			{/* Services Section */}
			<section className="text-center py-16">
				<h2 className="xl:text-5xl sm:text-xl font-bold mb-16">Our Services</h2>

				<div className="grid grid-cols-1 sm:w-full sm:pl-2 sm:pr-2   md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 max-w-5xl mx-auto">
					<div className="p-6 bg-white  sm:text-center shadow rounded-lg transition transform hover:scale-105 hover:shadow-lg duration-300">
						<div className="text-4xl mb-4">
							{/* Software Development Icon */}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								className="w-12 h-12 mx-auto text-blue-500">
								<path d="M9 3H6a2 2 0 0 0-2 2v3M9 3h6a2 2 0 0 1 2 2v3m0 4V7a2 2 0 0 1 2-2h3m-9 0V7a2 2 0 0 0-2-2H6m3 0H9a2 2 0 0 0-2 2v3" />
							</svg>
						</div>
						<h3 className="font-bold sm:text-sm xl:text-lg">
							Software Development
						</h3>
						<p className="text-gray-700 sm:text-xs xl:text-base">
							Software development refers to a set of computer science
							activities dedicated to creating and deploying software solutions.
						</p>
					</div>

					<div className="p-6 bg-white shadow rounded-lg transition transform hover:scale-105 hover:shadow-lg duration-300">
						<div className="text-4xl mb-4">
							{/* Mobile App Development Icon */}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								className="w-12 h-12 mx-auto text-blue-500">
								<path d="M12 2a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h6zm0 0V1H6a2 2 0 0 0-2 2v3h8z" />
							</svg>
						</div>
						<h3 className="font-bold sm:text-sm xl:text-lg">
							Mobile App Development
						</h3>
						<p className="text-gray-700 sm:text-xs xl:text-base">
							We build applications for smartphones and tablets, optimized for a
							seamless mobile experience.
						</p>
					</div>

					<div className="p-6 bg-white shadow rounded-lg transition transform hover:scale-105 hover:shadow-lg duration-300">
						<div className="text-4xl mb-4">
							{/* Web Development Icon */}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								className="w-12 h-12 mx-auto text-blue-500">
								<path d="M2 3h20v18H2z" />
							</svg>
						</div>
						<h3 className="font-bold sm:text-sm xl:text-lg">Web Development</h3>
						<p className="text-gray-700 sm:text-xs xl:text-base">
							Web development that creates dynamic and responsive websites for
							the modern web, with a focus on performance.
						</p>
					</div>

					<div className="p-6 bg-white shadow rounded-lg transition transform hover:scale-105 hover:shadow-lg duration-300">
						<div className="text-4xl mb-4">
							{/* Desktop App Development Icon */}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								className="w-12 h-12 mx-auto text-blue-500">
								<path d="M3 4v13h18V4H3zM3 17h18v2H3v-2z" />
							</svg>
						</div>
						<h3 className="font-bold sm:text-sm xl:text-lg">
							Desktop App Development
						</h3>
						<p className="text-gray-700 sm:text-xs xl:text-base">
							We design robust and reliable desktop applications for Windows,
							Linux, and macOS to meet business needs.
						</p>
					</div>

					<div className="p-6 bg-white shadow rounded-lg transition transform hover:scale-105 hover:shadow-lg duration-300">
						<div className="text-4xl mb-4">
							{/* Artificial Intelligence Icon */}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								className="w-12 h-12 mx-auto text-blue-500">
								<path d="M14.7 10.7l-4.7-4.7-2.5 2.5 4.7 4.7 2.5-2.5zM3 12c0 4.6 3.4 8.4 7.8 8.9-.2-.7-.3-1.3-.3-2.1s.1-1.4.3-2.1C6.4 16.4 3 14.6 3 12zm6.8 5.6l-1.4-1.4c-2-.8-3.4-2.5-3.4-4.2 0-3 2.5-5.4 5.6-5.4 3.1 0 5.6 2.5 5.6 5.6s-2.5 5.5-5.6 5.5c-.5 0-1-.1-1.5-.3-.6.9-1.4 1.5-2.4 1.5z" />
							</svg>
						</div>
						<h3 className="font-bold sm:text-sm xl:text-lg">
							Artificial Intelligence
						</h3>
						<p className="text-gray-700 sm:text-xs xl:text-base">
							AI solutions for smarter automation, data analysis, and
							decision-making powered by machine learning.
						</p>
					</div>

					{/* SAP Section Card */}
					<div className="p-6 bg-white shadow rounded-lg transition transform hover:scale-105 hover:shadow-lg duration-300">
						<div className="text-4xl mb-4">
							{/* SAP Icon */}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								className="w-12 h-12 mx-auto text-blue-500">
								<path d="M3 3h18v18H3z" />
							</svg>
						</div>
						<h3 className="font-bold sm:text-sm xl:text-lg ">SAP Solutions</h3>
						<p className="text-gray-700 sm:text-xs xl:text-base">
							Leverage SAP technologies for enterprise resource planning, data
							analytics, and business automation to streamline processes and
							boost operational efficiency.
						</p>
					</div>

					{/* DevOps Section Card */}
					<div className="p-6 bg-white shadow rounded-lg transition transform hover:scale-105 hover:shadow-lg duration-300">
						<div className="text-4xl mb-4">
							{/* Infinity Icon */}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								className="w-12 h-12 mx-auto text-green-500">
								<path d="M3 12c0-4.418 3.582-8 8-8s8 3.582 8 8-3.582 8-8 8-8-3.582-8-8z" />
								<path d="M12 3v4M12 17v4M3 12h4m10 0h4" />
							</svg>
						</div>
						<h3 className="font-bold sm:text-sm xl:text-lg ">DevOps </h3>
						<p className="text-gray-700 sm:text-xs xl:text-base">
							Implementing DevOps practices to enhance collaboration between
							development and operations teams, ensuring continuous integration,
							delivery, and faster deployment cycles.
						</p>
					</div>

					<div className="p-6 bg-white shadow rounded-lg transition transform hover:scale-105 hover:shadow-lg duration-300">
						<div className="text-4xl mb-4">
							{/* UI/UX Icon */}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								className="w-12 h-12 mx-auto text-blue-500">
								<path
									d="M4 6h16M4 12h8m-8 6h16"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<circle cx="12" cy="12" r="10" strokeWidth="2" />
							</svg>
						</div>
						<h3 className="font-bold sm:text-sm  xl:text-lg">
							UI/UX Development
						</h3>
						<p className="text-gray-700 sm:text-xs xl:text-base">
							Crafting intuitive and visually appealing user interfaces,
							focusing on user experience to enhance engagement and ensure
							seamless interactions across platforms.
						</p>
					</div>

					<div className="p-6 bg-white shadow rounded-lg transition transform hover:scale-105 hover:shadow-lg duration-300">
						<div className="text-4xl mb-4">
							{/* Test Automation Icon */}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								className="w-12 h-12 mx-auto text-orange-500">
								<path d="M18 6L6 18M6 6l12 12" />
							</svg>
						</div>
						<h3 className="font-bold sm:text-sm xl:text-lg">Test Automation</h3>
						<p className="text-gray-700 sm:text-xs xl:text-base">
							Implementing automated test frameworks to speed up testing
							processes, reduce human error, and ensure consistent results.
						</p>
					</div>

					<div className=" ml-[700px] blur-3xl w-96 rotate-12 h-72 -my-80 bg-gradient-to-tr from-purple-600 to-pink-500 rounded-full opacity-30 transform translate-x-3/4 translate-y-2/3"></div>
					<div className=" ml-[800px] blur-3xl w-72 rotate-45 h-56 -my-52 bg-gradient-to-tr from-purple-600 to-pink-500 rounded-full opacity-30 transform translate-x-3/4 translate-y-2/3"></div>
				</div>
			</section>

			{/* Industries Served Section */}
			<section className="text-center my-24 bg-white ">
				<h2 className="xl:text-5xl sm:text-xl sm:w-1/2 font-bold mb-16 mx-auto">
					Industries We Serve
				</h2>
				<div className="flex flex-wrap lg:flex-nowrap mr-4 xl:ml-36 items-start max-w-6xl space-x-48 ">
					{/* Left: Accordion Section */}
					<div className="w-full lg:w-1/2 sm:w-1/2 sm:text-sm mx-auto">
						{[
							{
								title: "Banking",
								content: (
									<>
										<p>
											The digital revolution is here to stay and is impacting
											the Banking sector tremendously. With newer technologies
											being harnessed to improve the experience of banking by
											fintechs, banks are finding it increasingly difficult to
											cope with challenges posed by these nimble and tech-savvy
											organizations.
										</p>
										<ul className="pl-5 mt-2">
											<li>System Migration / Upgrade Testing</li>
											<li>BAU Testing</li>
											<li>New System Testing</li>
										</ul>
									</>
								),
							},
							{
								title: "Insurance",
								content: (
									<p>
										SoftGrid Info is a specialist in testing a wide range of
										insurance systems like Life Asia, Elixir, HP, Graph Talk,
										and Ingenium and serves 33% of India’s Life Insurance
										companies. 70% of SoftGrid Info’s staff are domain
										specialists who can address any domain-related bugs in
										software.
									</p>
								),
							},
							{
								title: "Financial Services",
								content: (
									<p>
										Increase the efficiency of your financial applications with
										proactive testing and risk management. SoftGrid Info’s
										dedicated team of financial domain experts address any
										domain-related bugs in software. SoftGrid provides testing
										solutions to India’s top 3 broking houses and top 2
										commodity exchange firms.
									</p>
								),
							},
							{
								title: "E-Governance",
								content: (
									<p>
										SoftGrid Info is approved for an entire range of testing
										activities required for e-Governance software and solutions.
										This includes User Acceptance Testing (UAT) of e-Governance
										software, pre-bid eligibility testing of software products
										for e-Governance, and product certification testing.
									</p>
								),
							},
						].map((item, index) => (
							<div key={index} className="p-6 border-b border-gray-200">
								<div
									onClick={() => toggleAccordion(index)}
									className="cursor-pointer flex justify-between items-center">
									<h3 className="font-bold text-lg text-left">{item.title}</h3>
									<span>{activeIndex === index ? "-" : "↗"}</span>
								</div>
								<div
									className={`transition-all duration-300 ease-in-out overflow-hidden ${
										activeIndex === index
											? "max-h-screen opacity-100"
											: "max-h-0 opacity-0"
									}`}>
									<div className="mt-4 text-gray-700 text-sm text-gray-400 text-justify">
										{item.content}
									</div>
								</div>
							</div>
						))}
					</div>

					{/* Right: Image Section */}
					<div className="w-12 hidden xl:block lg:w-[400px] h-64 mt-8 lg:mt-0 lg:pl-12">
						<img
							src="/network.jpg"
							alt="Industries Illustration"
							className="w-full h-auto object-cover  rounded-lg shadow-lg"
						/>
					</div>
				</div>
			</section>

			{/* Testimonials Section */}
			<section
				ref={sectionRef}
				className="relative hidden tablet:block text-center py-4 h-auto bg-slate-900 text-white mb-2">
				{/* Gradient Blob at the top left */}
				<div className="absolute top-16 left-0 transform -translate-x-10 blur-3xl -translate-y-10 w-24 h-24 sm:w-48 sm:h-48 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 rounded-full opacity-50 animate-pulse transition-all"></div>

				<div className="container mx-auto flex flex-col lg:flex-row items-center">
					{/* Left Side: Placeholder or Image */}
					<div className="lg:w-1/2 justify-center items-center">
						<h2 className="text-6xl tablet:text-3xl font-semibold text-center text-white">
							SoftGrid Info<span className="text-6xl text-yellow-500">.</span>
						</h2>
						<span className="flex text-lg tablet:text-xl font-normal my-4 mx-12 xl:ml-52 w-full bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 bg-clip-text text-transparent ">
							Revolutionizing Your Digital Solutions
						</span>
					</div>

					{/* Right Side: Content */}
					<div className="lg:w-1/2 lg:pl-8 lg:pr-8">
						<h3 className="text-2xl font-bold mb-6 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 bg-clip-text text-transparent">
							They provide the most valuable pleasure as it were
						</h3>

						<p className="mb-8 text-gray-400">
							It is important to take care of the patient, to be followed by the
							patient, but it will happen at such a time that there is a lot of
							work and pain.
						</p>
						<div className="grid grid-cols-1 tablet:grid-cols-1 xl:grid-cols-2 gap-6">
							{/* Happy Clients */}
							<div className="p-6 bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
								<i className="bi bi-emoji-smile text-4xl text-yellow-400 mb-4 block"></i>
								<span
									ref={happyClientsRef}
									className="text-5xl font-bold bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 bg-clip-text text-transparent">
									150
								</span>
								<p className="mt-3 text-gray-300">
									<strong>Happy Clients:</strong> They result in our pleasure
									being something of the architect itself.
								</p>
							</div>

							{/* Projects */}
							<div className="p-6 bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
								<i className="bi bi-journal-richtext text-4xl text-blue-400 mb-4 block"></i>
								<span
									ref={projectsRef}
									className="text-5xl font-bold bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 bg-clip-text text-transparent">
									5
								</span>
								<p className="mt-3 text-gray-300">
									<strong>Projects:</strong> Get it, and with it, because the
									whole of those who praise it is spurned, and because it gives
									tan.
								</p>
							</div>

							{/* Years of Experience */}
							<div className="p-6 bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
								<i className="bi bi-clock text-4xl text-green-400 mb-4 block"></i>
								<span
									ref={experienceRef}
									className="text-5xl font-bold bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 bg-clip-text text-transparent">
									2
								</span>
								<p className="mt-3 text-gray-300">
									<strong>Years of Experience:</strong> Or he may seek some mode
									of convenience, for we lead either with pleasure or not.
								</p>
							</div>

							{/* Support */}
							<div className="p-6 bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
								<i className="bi bi-award text-4xl text-red-400 mb-4 block"></i>
								<span
									ref={supportRef}
									className="text-5xl font-bold bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 bg-clip-text text-transparent">
									70
								</span>
								<p className="mt-3 text-gray-300">
									<strong>Support:</strong> The harsher pains of other things
									where he will criticize him and no one will pad der.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/*Contact*/}
		</div>
	);
};

export default LandingPage;
